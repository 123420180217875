import React from 'react';
import styles from './EKYC.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const ekycICChipItems = [
  {
    ImgComponent: () => {
      return (
        <div className={styles.appIconContainer}>
          <div className={styles.appIcon}>
            <img
              src={require('../../../images/qr-code-guide.svg')}
              alt={'QRcode'}
            />
          </div>
        </div>
      );
    },
    figCaption: 'ペイディアプリをダウンロード。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-1-ICChip.svg')}
        alt={'身分証明書のアイコン'}
      />
    ),
    figCaption:
      'マイナンバーカード発行時に登録した、英数字６-16桁の署名用電子証明書の暗証番号を入力してください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-2-ICChip.svg')}
        alt={'顔写真をスマートフォンで撮影中のアイコン'}
      />
    ),
    figCaption:
      'マイナンバーカードのICチップをスマートフォンでスキャンしてください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-3-ICChip.svg')}
        alt={'SMSで通知が届いているアイコン'}
      />
    ),
    figCaption: '必要事項を入力して完了です。',
  },
];

const ekycItems = [
  {
    ImgComponent: () => {
      return (
        <div className={styles.appIconContainer}>
          <div className={styles.appIcon}>
            <img
              src={require('../../../images/qr-code-guide.svg')}
              alt={'QRcode'}
            />
          </div>
        </div>
      );
    },
    figCaption: 'ペイディアプリをダウンロード。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-1.svg')}
        alt={'身分証明書のアイコン'}
      />
    ),
    figCaption:
      '運転免許証またはマイナンバーカードをカメラで撮影してください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-2.svg')}
        alt={'顔写真をスマートフォンで撮影中のアイコン'}
      />
    ),
    figCaption: '顔写真を撮影してください。',
  },
  {
    ImgComponent: () => (
      <img
        src={require('../../../images/guide-eKYC-3.svg')}
        alt={'SMSで通知が届いているアイコン'}
      />
    ),
    figCaption: '必要事項を入力して完了です。',
  },
];

const EKYC = () => {
  return (
    <section className={styles.section} id="kyc">
      <h2 className={styles.sectionTitle}>本人確認をしてみよう</h2>
      <div className={styles.sectionTitleDescription}>
        <p>
          本人確認をすることで、より安心してお買い物をお楽しみいただけます。また、ペイディカードや分割手数料無料*の3・6・12回あと払いなど、便利な機能が利用可能になります。
          <br />
          <br />
          <br />
          <span className={styles.note}>*口座振替・銀行振込のみ無料</span>
        </p>
      </div>
      <HorizontalScrollNavigation
        badge="最短2分で完了"
        bgContainer={styles.bgContainerICCHip}
        title="マイナンバーカードのICチップを読み取って本人確認"
        items={ekycICChipItems}
      />
      <HorizontalScrollNavigation
        bgContainer={styles.bgContainerEKYC}
        title="運転免許証またはマイナンバーカードを撮影して本人確認"
        items={ekycItems}
      />
    </section>
  );
};

export default EKYC;
