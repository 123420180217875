import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './Hero.module.scss';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerPC: file(relativePath: { eq: "guide-hero-pc.png" }) {
        childImageSharp {
          fluid(maxWidth: 4800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerSP: file(relativePath: { eq: "guide-hero-sp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const bannerSources = [
    {
      ...data.bannerSP.childImageSharp.fluid,
      media: '(max-width: 1024px)',
    },
    {
      ...data.bannerPC.childImageSharp.fluid,
      media: '(max-width: 4800px)',
    },
  ];

  return (
    <header className={styles.header}>
      <div className={styles.bannerWrapper}>
        <Img
          className={styles.banner}
          fluid={bannerSources}
          alt="banner"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
        <div className={styles.headerTitleContainer}>
          <h1 className={styles.headerTitle}>ご利用ガイド</h1>
        </div>
      </div>
    </header>
  );
};

export default Hero;
