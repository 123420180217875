import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import Img from 'gatsby-image';
import styles from './Budget.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const Budget = () => {
  const data = useStaticQuery(graphql`
    query {
      budgetHome: file(relativePath: { eq: "guide-budget-home.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      budget: file(relativePath: { eq: "guide-budget.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const items = [
    {
      ImgComponent: () => {
        return (
          <div className={styles.appIconContainer}>
            <div className={styles.appIcon}>
              <img
                src={require('../../../images/qr-code-guide.svg')}
                alt={'QRcode'}
              />
            </div>
          </div>
        );
      },
      figCaption: 'ペイディアプリから本人確認をする。',
    },
    {
      ImgComponent: () => (
        <Img
          fixed={data.budgetHome.childImageSharp.fixed}
          alt={'ペイディアプリのホーム画面の予算設定の画面'}
        />
      ),
      figCaption: 'ホーム画面の「予算を設定する」をタップ。',
    },
    {
      ImgComponent: () => (
        <Img
          fixed={data.budget.childImageSharp.fixed}
          alt={'ペイディアプリの予算設定の画面'}
        />
      ),
      figCaption: '予算を設定して完了。',
    },
  ];

  return (
    <section className={cx(styles.section, styles.lastSection)} id="budget">
      <h2 className={styles.sectionTitle}>予算設定で使い過ぎを防止しよう</h2>
      <div className={styles.sectionTitleDescription}>
        <p>ご利用金額が予算を超えると、メールとプッシュ通知でご連絡します。</p>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default Budget;
