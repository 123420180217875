import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import cx from 'classnames';
import styles from './Payment.module.scss';
import HorizontalScrollNavigation from '../HorizontalScrollNavigation';

const Payment = () => {
  const data = useStaticQuery(graphql`
    query {
      settlement: file(relativePath: { eq: "guide-settlement.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      conbiniSelection: file(
        relativePath: { eq: "guide-conbini-selection.png" }
      ) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      barcodePayment: file(relativePath: { eq: "guide-barcode-payment.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bankOption: file(relativePath: { eq: "guide-bank-option.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bankSetup: file(relativePath: { eq: "guide-bank-setup.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tabAccount: file(relativePath: { eq: "guide-tab-account.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      accountScreen: file(relativePath: { eq: "guide-account-screen.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bankOption: file(relativePath: { eq: "guide-bank-option.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bankTransfer: file(relativePath: { eq: "guide-bank-transfer.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tabUsage: file(relativePath: { eq: "guide-tab-usage.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      payNow: file(relativePath: { eq: "guide-pay-now.png" }) {
        childImageSharp {
          fixed(width: 184) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const paymentProcessItems = React.useMemo(
    () => [
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-check-payment-method-1.svg')}
            alt={'カレンダーのアイコン'}
            {...props}
          />
        ),
        figCaption:
          '１か月に何回お買い物をしても、お支払いはまとめて翌月でOK。',
      },
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-check-payment-method-2.svg')}
            alt={'ペイディアプリのアカウント画面のアイコン'}
            {...props}
          />
        ),
        figCaption:
          'ペイディアプリの「アカウント」から、「お支払い方法」を設定する。',
      },
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-check-payment-method-3.svg')}
            alt={'ご請求金額がSMSで送信されていることを示すアイコン'}
            {...props}
          />
        ),
        figCaption: '翌月1日～5日の間にメールとSMSでご請求金額をお知らせ。',
      },
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-check-payment-method-4.svg')}
            alt={'カレンダーのアイコン'}
            {...props}
          />
        ),
        figCaption: '翌月27日までにお支払いください。',
      },
    ],
    []
  );

  const conbiniPaymentProcessItems = React.useMemo(
    () => [
      {
        ImgComponent: () => (
          <Img
            fixed={data.settlement.childImageSharp.fixed}
            alt={'ペイディアプリのお支払い画面'}
          />
        ),
        figCaption: 'ホーム画面の「お支払い」をタップ。',
      },
      {
        ImgComponent: () => (
          <Img
            fixed={data.conbiniSelection.childImageSharp.fixed}
            alt={'ペイディアプリのコンビニ一覧画面'}
          />
        ),
        figCaption: '「取扱いコンビニ」をタップ。',
      },
      {
        ImgComponent: () => (
          <Img
            fixed={data.barcodePayment.childImageSharp.fixed}
            alt={'バーコードを用いてお支払いしている画面'}
          />
        ),
        figCaption: 'レジでアプリの画面を提示してお支払いください。',
      },
    ],
    [data]
  );

  const bankPaymentProcessItems = React.useMemo(
    () => [
      {
        ImgComponent: () => (
          <Img
            fixed={data.settlement.childImageSharp.fixed}
            alt={'ペイディアプリのお支払い画面'}
          />
        ),
        figCaption: 'ホーム画面の「お支払い」をタップ。',
      },
      {
        ImgComponent: () => (
          <Img
            fixed={data.bankOption.childImageSharp.fixed}
            alt={'ペイディアプリのお客様専用振込口座ボタンの画像'}
          />
        ),
        figCaption: '「お客様専用振込口座」をタップ。',
      },
      {
        ImgComponent: () => (
          <Img
            fixed={data.bankSetup.childImageSharp.fixed}
            alt={'ペイディアプリのお客様専用振込口座の画面'}
          />
        ),
        figCaption: 'お客様専用振込口座を確認。',
      },
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-bank.svg')}
            alt={'銀行のアイコン'}
            {...props}
          />
        ),
        figCaption: '銀行振込でお支払いください。',
      },
    ],
    [data]
  );

  const bankTransferProcessItems = React.useMemo(
    () => [
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.tabAccount.childImageSharp.fixed}
            alt={'ペイディアプリのタブ画像'}
            {...props}
          />
        ),
        figCaption: '「アカウント」をタップ。',
      },
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.accountScreen.childImageSharp.fixed}
            alt={'ペイディアプリのお支払い方法ボタンの画像'}
            {...props}
          />
        ),
        figCaption: '「お支払い方法」をタップ。',
      },
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.bankTransfer.childImageSharp.fixed}
            alt={'ペイディアプリの口座振替ボタンの画像'}
            {...props}
          />
        ),
        figCaption: '「口座振替」から銀行口座を登録してください。',
      },
    ],
    [data]
  );

  const payNowProcessItems = React.useMemo(
    () => [
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.tabUsage.childImageSharp.fixed}
            alt={'ペイディアプリのタブ画像'}
            {...props}
          />
        ),
        figCaption: '「ご利用履歴」をタップ。',
      },
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.payNow.childImageSharp.fixed}
            alt={'ご利用履歴画面'}
            {...props}
          />
        ),
        figCaption: '「すぐ払いへ」をタップ。',
      },
      {
        ImgComponent: (props) => (
          <Img
            fixed={data.conbiniSelection.childImageSharp.fixed}
            alt={'コンビニを選択している画面'}
            {...props}
          />
        ),
        figCaption: '支払うコンビニを選ぶ。',
      },
      {
        ImgComponent: (props) => (
          <img
            src={require('../../../images/guide-convini.svg')}
            alt={'コンビニのアイコン'}
            {...props}
          />
        ),
        figCaption: '選択したコンビニでお支払いください。',
      },
    ],
    [data]
  );

  return (
    <section className={styles.section} id="payment">
      <h2 className={styles.sectionTitle}>支払い方法を確認しよう</h2>
      <div className={styles.sectionTitleDescription}>
        <p>
          あなたのライフスタイルにあわせて、支払いのタイミングやお支払い方法を自由に選ぶことができます。
        </p>
      </div>
      <HorizontalScrollNavigation items={paymentProcessItems} />
      <section className={styles.subsection} id="payment-convenience-store">
        <h3 className={styles.subsectionTitle}>コンビニ払いで支払う</h3>
        <div className={styles.sectionTitleDescription}>
          <p>
            下記のコンビニで、ペイディアプリの画面を見せてお支払いください。
          </p>
        </div>
        <div className={styles.conbiniIconsContainer}>
          <img
            src={require('../../../images/guide-seven-eleven-icon.svg')}
            alt={'ファミリーマートのアイコン'}
            width="72"
            height="72"
            className={cx(styles.conbiniIcon, styles.pc)}
          />
          <img
            src={require('../../../images/guide-seven-eleven-icon.svg')}
            alt={'セブンイレブンのアイコン'}
            width="72"
            height="72"
            className={cx(styles.conbiniIcon, styles.sp)}
          />
          <img
            src={require('../../../images/guide-lawson-icon.svg')}
            alt={'ローソンのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
          <img
            src={require('../../../images/guide-ministop-icon.svg')}
            alt={'ミニストップのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
          <img
            src={require('../../../images/guide-seicomart-icon.svg')}
            alt={'セイコーマートのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
        </div>
        <HorizontalScrollNavigation items={conbiniPaymentProcessItems} />
        <p
          className={styles.stepFooterDescription}
          style={{ marginBottom: 40 }}
        >
          アプリをお持ちでない方は、下記のコンビニで、メールとSMSでご連絡する「お支払い受付番号」でお支払いください。
        </p>
        <div className={styles.conbiniIconsContainer}>
          <img
            src={require('../../../images/guide-familymart-icon.svg')}
            alt={'ファミリーマートのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
          <img
            src={require('../../../images/guide-lawson-icon.svg')}
            alt={'ローソンのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
          <img
            src={require('../../../images/guide-ministop-icon.svg')}
            alt={'ミニストップのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
          <img
            src={require('../../../images/guide-seicomart-icon.svg')}
            alt={'セイコーマートのアイコン'}
            width="72"
            height="72"
            className={styles.conbiniIcon}
          />
        </div>
        <div className={styles.stepFooterLink}>
          <Link
            to="https://faq.paidy.com/%E3%82%B3%E3%83%B3%E3%83%93%E3%83%8B%E3%81%A7%E3%81%AE%E6%94%AF%E6%89%95%E3%81%84%E6%96%B9%E6%B3%95%E3%81%8C%E3%82%8F%E3%81%8B%E3%82%89%E3%81%AA%E3%81%84"
            target="_blank"
            referrerPolicy
            rel="noreferrer noopener"
            className={styles.link}
          >
            詳しいお支払い方法はこちら
          </Link>
        </div>
      </section>
      <section className={styles.subsection} id="payment-bank-transfer">
        <h3 className={styles.subsectionTitle}>銀行振込で支払う</h3>
        <div className={styles.sectionTitleDescription}>
          <p>
            ペイディアプリで「お客様専用振込口座」をご確認の上、お振り込みください。
          </p>
        </div>
        <HorizontalScrollNavigation items={bankPaymentProcessItems} />
        <p className={styles.stepFooterDescription}>
          アプリをお持ちでない方は、ご請求確定後にお送りするメールとSMSから「お客様専用振込口座」をご確認ください。
        </p>
      </section>
      <section className={styles.subsection} id="payment-account-transfer">
        <h3 className={styles.subsectionTitle}>口座振替で支払う</h3>
        <div className={styles.sectionTitleDescription}>
          <p>
            ペイディアプリから銀行口座を登録すると、毎月自動的にご請求金額が引き落とされます。
            <br />
            <br />
            <span className={styles.note}>
              ※口座振替の結果は、引き落とし日から約3〜5営業日後にご利用履歴に反映されます。
            </span>
          </p>
        </div>
        <HorizontalScrollNavigation items={bankTransferProcessItems} />
        <p className={styles.stepFooterDescription}>
          アプリをお持ちでない方は、MyPaidyから口座振替をご登録ください。
          <br />
          <Link
            to="https://my.paidy.com/login"
            target="_blank"
            referrerPolicy
            rel="noreferrer noopener"
            className={styles.link}
          >
            MyPaidyはこちら
          </Link>
        </p>
      </section>
      <section className={styles.subsection} id="payment-paynow">
        <h3 className={styles.subsectionTitle}>すぐ払いで支払う</h3>
        <div className={styles.sectionTitleDescription}>
          <p>
            翌月の確定を待たずに、お好きなタイミングでご請求をコンビニ払いできます。
          </p>
        </div>
        <HorizontalScrollNavigation items={payNowProcessItems} />
        <div className={styles.stepFooterDescription}>
          <p className={styles.note}>
            ※すぐ払いはコンビニ払いのみ利用可能です。毎月のご請求のお支払い方法を口座振替に設定している場合、ペイディのご利用明細にお引き落としが反映された翌日から14日までの間、コンビニ払いですぐ払いをご利用いただけます。
          </p>
        </div>
      </section>
    </section>
  );
};

export default Payment;
