import React from 'react';
import cx from 'classnames';
import { Link } from 'react-scroll';
import styles from './Outline.module.scss';

const Outline = () => {
  return (
    <section className={cx(styles.section, styles.firstSection)}>
      <h2 className={styles.mainTitle}>あと払いペイディとは</h2>
      <p className={styles.outlineDescription}>
        AmazonやQoo10のほか、Visaマークのあるネットショップならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。
      </p>
      <nav className={styles.nav}>
        <ol className={styles.navInner}>
          <li className={styles.navItem}>
            <Link
              className={styles.navLink}
              to="shopping"
              smooth
              duration={500}
            >
              ▼ ペイディでお買い物を楽しもう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={styles.navLink}
              to="paidy-app"
              smooth
              duration={500}
            >
              ▼ アプリでもっと便利に使おう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="payment" smooth duration={500}>
              ▼ 支払い方法を確認しよう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="kyc" smooth duration={500}>
              ▼ 本人確認をしてみよう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              className={styles.navLink}
              to="paidy-card"
              smooth
              duration={500}
            >
              ▼ ペイディカードを使ってみよう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="3pay" smooth duration={500}>
              ▼ 3・6・12回あと払いを使ってみよう
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="budget" smooth duration={500}>
              ▼ 予算設定で使い過ぎを防止しよう
            </Link>
          </li>
        </ol>
      </nav>
      <Link
        to="paidy_root"
        smooth
        duration={500}
        offset={-64}
        className={styles.floatingAnchorButton}
      >
        <img
          src={require('../../images/guide-arrow-up.svg')}
          alt="上向き矢印のアイコン"
          width="17"
          height="17"
        />
      </Link>
    </section>
  );
};

export default Outline;
